import { useState } from "react";
import {
    createUserWithOrganizations,
    getOrganizations,
    IUserCreateWithOrganizations,
} from "@api";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import {
    FormAutoCompleteElement,
    FormButton,
    FormCheckboxElement,
    FormContainer,
    FormTextFieldElement,
} from "@rhf-kit/mui";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BackButton, DashboardContainer } from "@components";
import { useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";

export const CreateUser = () => {
    const [create, setCreate] = useState(true);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { mutate: createUser, isPending } = useMutation({
        mutationFn: createUserWithOrganizations,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
            setCreate(false);

            enqueueSnackbar("User created", { variant: "success" });
            // Go back to the list of users
            navigate(-1);
        },
        onError: () => {
            enqueueSnackbar("Failed to create user", { variant: "error" });
        },
    });

    const { data: organizationOptions, isPending: loadingOrganizationOptions } =
        useQuery({
            queryKey: ["organization-options"],
            queryFn: () => getOrganizations(),
        });

    const handleSubmit = (data: IUserCreateWithOrganizations) => {
        createUser(data);
    };

    return create ? (
        <DashboardContainer title={"Users"}>
            <Box>
                <BackButton />
            </Box>
            <Card
                elevation={1}
                sx={{
                    width: "100%",
                    p: 4,
                    maxWidth: "lg",
                }}
            >
                <CardContent>
                    <Stack alignItems="center" direction="row" sx={{ mb: 2 }}>
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            flexGrow={1}
                            textAlign="center"
                        >
                            Create New User
                        </Typography>
                    </Stack>
                    <FormContainer
                        defaultValues={{
                            first_name: "",
                            last_name: "",
                            email: "",
                            organizations: [],
                            is_admin: false,
                        }}
                        onSuccess={handleSubmit}
                    >
                        <Stack spacing={3}>
                            <FormTextFieldElement
                                fullWidth
                                name="first_name"
                                label="First Name"
                                required
                            />
                            <FormTextFieldElement
                                fullWidth
                                name="last_name"
                                label="Last Name"
                                required
                            />
                            <FormTextFieldElement
                                fullWidth
                                name="email"
                                label="Email"
                                required
                            />
                            <FormAutoCompleteElement
                                multiple
                                name="organizations"
                                label="Organizations"
                                loading={loadingOrganizationOptions}
                                getOptionLabel={(option) =>
                                    option?.name ?? option
                                }
                                options={organizationOptions?.items ?? []}
                            />
                            <FormCheckboxElement
                                name="is_admin"
                                label="Is Admin"
                            />{" "}
                            <FormButton
                                variant="contained"
                                color="primary"
                                loading={isPending}
                                loadingPosition={"end"}
                            >
                                Create User
                            </FormButton>
                        </Stack>
                    </FormContainer>
                </CardContent>
            </Card>
        </DashboardContainer>
    ) : null;
};

export default CreateUser;
