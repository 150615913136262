export const appRoutes = {
    login: "/login",
    register: "/register",
    forgotPassword: "/password/forgot",
    resetPassword: "/password/reset",
    base: "/",
    organization: {
        base: "/organization",
        map: {
            base: "/organization/map",
            location: {
                base: "/organization/map/:locationId",
            },
        },
        locations: {
            base: "/organization/locations",
        },
        droneData: {
            base: "/organization/drone-data",
        },
        home: "/organization/",
        settings: "/organization/settings",
    },
    admin: {
        base: "/admin",
        users: `/admin/users`,
        editUser: `/admin/users/:userId/edit`,
        createUser: `/admin/users/create`,
        organizations: {
            base: "/admin/organizations",
            edit: `/admin/organizations/:id/edit`,
        },
    },
    account: {
        base: "/account",
        security: "/account/security",
    },
};

interface RouteParams {
    [key: string]: string | number;
}

interface QueryParams {
    [key: string]: string | number;
}

export function generateRoute(
    route: string,
    params: RouteParams,
    queryParams?: QueryParams
): string {
    const routeWithParams = route.replace(/:\w+/g, (match) => {
        const key = match.substring(1);
        if (key in params) {
            return String(params[key]);
        }
        return match;
    });

    if (queryParams) {
        const queryParamKeys = Object.keys(queryParams);
        if (queryParamKeys.length > 0) {
            const queryParamString = queryParamKeys
                .map((key) => `${key}=${queryParams[key]}`)
                .join("&");
            return `${routeWithParams}?${queryParamString}`;
        }
    }

    return routeWithParams;
}
